<template>
  <div>
    <v-container fluid class="mx-auto" style="max-width:1152px">
      <div class="d-flex align-center justify-space-between header-app px-6">
        <div class="fsize24" id="current_name">{{this.currentAppData.appName}}</div>
        <div class="green-btn text-center">Active</div>
      </div>
      <v-row class="ma-0">
        <v-col cols="12" xs="12" sm="12" md="6" lg="7" class="px-5">
          <div class="fsize22 pb-5">App details</div>
          <div class="pb-1 secondaryColor" id="app_name_label">App name:</div>
          <v-text-field dense outlined height="48" id="app_name_inp" v-model="appName" autocomplete="off"></v-text-field>
          <div class="pb-1 secondaryColor" id="app_name_icon">App icon:</div>
          <v-file-input type="file" v-model="appIcon" height="48px" id="app_icon_inp" dense append-icon="mdi-folder-open" prepend-icon="" autocomplete="off" outlined>
          </v-file-input>
          <div class="pb-1 secondaryColor" id="redirection_url_label">Redirect URL</div>
          <v-text-field dense outlined height="48" id="redirection_url_inp" v-model="redirectUrl" autocomplete="off"></v-text-field>
          <div class="pb-1 secondaryColor" id="postback_url_label">Postback URL</div>
          <v-text-field dense outlined height="48" id="postback_url_inp" v-model="postbackUrl" autocomplete="off"></v-text-field>
          <div class="pb-1 secondaryColor" id="status_label">Status</div>
          <v-autocomplete dense outlined height="48" id="status_select" v-model="status" :items="['Active','Paused']"></v-autocomplete>
          <div class="pb-1 secondaryColor" id="description_label">Description</div>
          <v-textarea outlined placeholder="Enter the Description" id="description_inp" v-model="description"></v-textarea>
          <div class="pb-1 secondaryColor" id="contact_name_label">Contact name</div>
          <v-text-field dense outlined height="48" autocomplete="off" id="contact_name_inp" v-model="contactName"></v-text-field>
          <div class="pb-1 secondaryColor" id="mobile_no_label">Mobile No</div>
          <v-text-field dense outlined height="48" id="mobile_no_inp" autocomplete="off" v-model="mobileNo"></v-text-field>
          <div class="pb-1 secondaryColor" id="email_label">Email Id</div>
          <v-text-field dense outlined height="48" id="email_label_inp" autocomplete="off" v-model="emailId"></v-text-field>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" lg="5" class="bg_grey pl-14">
          <div class="px-2 py-6" style="border-bottom:1px solid #ededed">
            <div class="mb-4 fsize22" id="app_code_label">App Code</div>
            <div class="px-4 mb-4 pa-2 code_btn" id="app_code_inp">{{this.currentAppData.apiKey}}</div>
            <div class="py-3">
              <div class="mb-4 fsize22" id="secret_key_inp">Secret Key</div>
              <div class="px-4 mb-4 pa-2 code_btn" id="secret_key_inp">{{this.vendorSecretKey}}</div>
            </div>
            <div class="py-3">
              <v-btn @click="secretRegenerateDialog = true" id="regenterate_btn" depressed color="black lighten-4" outlined class="text-capitalize fsize14 primaryColor">Re-generate API Secret</v-btn>
            </div>
          </div>
          <div class="px-2 py-6" style="border-bottom:1px solid #ededed">
            <v-btn id="delete_btn" @click="confirmationDialog = true" depressed color="black lighten-4" outlined class="text-capitalize primaryColor">
              <v-icon small>mdi-delete</v-icon>Delete this app
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <div class="d-flex justify-end my-10">
        <v-btn outlined depressed @click="$router.back()" width="90" id="cancel_btn" class="text-capitalize fsize14 mx-4">Cancel</v-btn>
        <v-btn @click="saveVendor()" depressed color="primary" id="save_btn" width="90" class="text-capitalize fsize14">Save</v-btn>
      </div>
    </v-container>
    <!-- overlay-color="#ededed" -->
    <v-dialog v-model="confirmationDialog" width="370">
      <v-card class="pa-4 cardBoxShadow rounded-lg" height="130">
        <div class="secondaryColor fsize14 d-flex justify-space-between">
          <div>Are you sure want to delete this app ?</div>
          <div>
            <v-icon @click="confirmationDialog = false" id="delete_dialog_icon" color="light-blue">mdi-close-circle-outline</v-icon>
          </div>
        </div>
        <div class="d-flex justify-end mt-10">
          <v-btn height="32" width="86" @click="deleteVendor()" id="delete_dialog_confirm_btn" depressed outlined text color="light-blue" class="text-capitalize white--text fsize12 mr-2">
            Confirm
          </v-btn>
          <v-btn height="32" width="86" @click="confirmationDialog = false" id="delete_dialog_cancel_btn" depressed color="black" outlined text class="text-capitalize fsize12">
            Cancel
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="secretRegenerateDialog" width="370" overlay-color="white">
      <v-card class="pa-4 cardBoxShadow rounded-lg" height="130">
        <div class="secondaryColor fsize14 d-flex justify-space-between">
          <div>Are you sure want to Regenerate API Secret ?</div>
          <div>
            <v-icon @click="secretRegenerateDialog = false" id="regenerate_icon" color="light-blue">mdi-close-circle-outline</v-icon>
          </div>
        </div>
        <div class="d-flex justify-end mt-10">
          <v-btn height="32" width="86" @click="reGenerateApiSecret()" id="regenerate_confirm_btn" depressed outlined text color="light-blue" class="text-capitalize white--text fsize12 mr-2">
            Confirm
          </v-btn>
          <v-btn height="32" width="86" @click="secretRegenerateDialog = false" id="regenerate_cancel_btn" depressed color="black" outlined text class="text-capitalize fsize12">
            Cancel
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import service from "../../store/httpService";
export default {
  data: () => ({
    currentAppData: {},
    appName: "",
    appIcon: [],
    redirectUrl: "",
    postbackUrl: "",
    status: "",
    description: "",
    userId: "",
    contactName: "",
    mobileNo: "",
    emailId: "",
    confirmationDialog: false,
    secretRegenerateDialog: false,
  }),
  computed: {
    ...mapGetters("vendor", {
      vendorList: "getVendorList",
      vendorSecretKey: "getVendorSecretKey",
    }),
  },
  methods: {
    async deleteVendor() {
      let json = {
        appId: this.currentAppData.appId,
      };
      await this.$store.dispatch("vendor/deleteVendor", json);
    },
    async reGenerateApiSecret() {
      let json = {
        appId: this.currentAppData.appId,
      };
      await this.$store.dispatch("vendor/reGenerateSecretKey", json);
      this.secretRegenerateDialog = false;
    },
    async setInitialData(data) {
      this.appName = data.appName;
      this.appIcon = data.appIcon;
      this.redirectUrl = data.redirectUrl;
      this.postbackUrl = data.postbackUrl;
      this.status = data.authorizationStatus == "1" ? 'Active' : 'Paused';
      this.description = data.description;
      this.userId = data.clientId;
      this.contactName = data.contactName;
      this.mobileNo = data.mobieNo;
      this.emailId = data.email;
    },
    async saveVendor() {
      this.$store.dispatch("vendor/updateVendor", {
        appName: this.appName,
        redirectUrl: this.redirectUrl,
        postbackUrl: this.redirectUrl,
        description: this.description,
        appId: this.$route.query.appId,
        contactName: this.contactName,
        mobieNo: this.mobileNo,
        email: this.emailId,
      });
    },
    getVendorList() {
      this.$store.dispatch("vendor/getVendors");
    },
  },
  created() {
    // var data = localStorage.getItem("currentAppData");
    // if (data) {
    //   this.currentAppData = JSON.parse(localStorage.getItem("currentAppData"));
    //   this.setInitialData(this.currentAppData);
    // }
    // this.getVendorList();
  },
  async created() {
    await service.getVendorDetails().then((res) => {
      this.$store.commit("vendor/setVendorList", res.data.result);
   }, (error) => {
    this.$store.dispatch('unAuthorized', error)
            }).finally(() => {  })
    if (this.vendorList) {
      await this.vendorList.forEach((element) => {
        if (parseInt(this.$route.query.appId) == parseInt(element.appId)) {
          this.currentAppData = element;
          this.$store.commit("vendor/setVendorSecretKey", element.apiSecret);
        }
      });
      await this.setInitialData(this.currentAppData);
    }
  },
};
</script>